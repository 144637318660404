<template>
  <div>
    <b-table-custom ref="tableData"
        :table-data="dataObjectList"
        :table-columns="columns"
        :is-data-loading="isTableLoading"
        @checkOperationStatus="checkOperationStatus"
        @selected-item-onchange="selectedItemOnChange"
    >
      <template #button_event >
        <b-dropdown class="menu-button"
                    variant="primary"
                    size="sm"
        >
          <template #button-content>
            <feather-icon icon="SettingsIcon" class="mr-1"/>
            <span>Action Menu</span>
          </template>
          <template v-for="(menuItem, index) in actionMenuList">
            <b-dropdown-divider v-if="menuItem['type'] === 'divider'" :key="index"/>
            <b-dropdown-item v-else
                             :key="index"
                             @click="openModal(menuItem['name'])"
                             :disabled="menuItem['disabled']"
            >
              <font-awesome-icon :icon="menuItem['icon']" :class="`text-${menuItem['color']}`" />
              <span>{{ menuItem['label'] }}</span>
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </template>

      <template #cell(ip)="{data}">
        <a :href="`/proxy/detail/${data['item']['id']}`" target="_blank">
          <strong class="text-primary">{{ data['value'] }}</strong>
        </a>
      </template>
      <template #cell(plan)="{data}">
        <strong class="text-info">{{ data['item']['plan']['name'] }}</strong>
        <br/>
        <span class="text-success">{{ currencyFormat(data['item']['plan']['price']) }}</span>
      </template>
      <template #cell(owner)="{data}">
        <strong class="text-primary">
          <a :href="data['item']['owner']['facebook_url']"
             target="_blank"
          >
            {{ data['item']['owner']['full_name'] }}
          </a>
        </strong>
      </template>
      <template #cell(bandwidth)="{data}">
        <progress-bar :value="data['value']['total_rx'] + data['value']['total_tx']" :max="data['value']['total_limit']">
          <strong>{{ numberFormat((data['value']['total_rx'] + data['value']['total_tx']).toFixed(2)) }} / {{ numberFormat(data['value']['total_limit']) }} GB</strong>
        </progress-bar>
      </template>

      <template #cell(view_detail)="{data}">
        <b-badge
            :href="`/proxy/detail/${data['item']['id']}`"
            variant="success"
            target="_blank"
        >
          <div class="d-flex">
            <feather-icon icon="EyeIcon" class="mr-50"/>
            <span class="align-content-center">Chi tiết</span>
          </div>
        </b-badge>
      </template>
      <template #menu="{item}">
        <template v-for="(menuItem, index) in menuButtonList">
          <b-dropdown-divider v-if="menuItem['type'] === 'divider'"/>
          <b-dropdown-item v-else-if="menuItem['visible'] === undefined || menuItem['visible'](item)"
                           :disabled="menuItem['disabled']"
                           @click="openModal(menuItem['name'], item)"
          >
            <font-awesome-icon :icon="menuItem['icon']" :class="`text-${menuItem['color']}`" />
            <span>{{ menuItem['label'] }}</span>
          </b-dropdown-item>
        </template>
      </template>
    </b-table-custom>

    <custom-modal id="proxy-service-modal"
                  ref="proxy-service-modal"
                  :title="currentModal.title"
                  :variant="currentModal.variant"
                  :ok-title="currentModal.okTitle"
                  :cancel-title="currentModal.cancelTitle"
                  :is-manual-close="currentModal.isManualClose"
                  @ok="currentModal.okAction"
    >
      <b-card-text v-if="modalName === 'modal-edit'">
        <custom-input v-model="dataObjectSelectedClone['ip']"
                      label="IP"
        />
        <user-list v-model="dataObjectSelectedClone['owner']['id']"
                   label="Chủ sở hữu"
        />
        <custom-select v-model="dataObjectSelectedClone['plan']['id']"
                       reduce="id"
                       display-field="name"
                       :options="proxyPlanList"
                       label="Chọn gói Proxy"
        >
          <template #option="{item}">
            <span><small><strong>{{ item['name'] }}</strong> (<span class="text-success">{{ currencyFormat(item['selling_price']) }}</span>)</small></span>
          </template>
          <template #selected-option="{item}">
            <span><small><strong>{{ item['name'] }}</strong> (<span class="text-success">{{ currencyFormat(item['selling_price']) }}</span>)</small></span>
          </template>
        </custom-select>
        <custom-input v-model="dataObjectSelectedClone['lev_service_id']"
                      label="LEV service ID"
        />
        <b-form-row>
          <date-time-picker class="col"
                            v-model="dataObjectSelectedClone['start_date']"
                            label="Ngày bắt đầu"/>
          <date-time-picker class="col"
                            v-model="dataObjectSelectedClone['end_date']"
                            label="Ngày kết thúc"/>
        </b-form-row>
        <b-form-group>
          <label>Ghi chú</label>
          <b-textarea v-model="dataObjectSelectedClone['note']"/>
        </b-form-group>
        <b-form-group>
          <label>Ghi chú của admin</label>
          <b-textarea v-model="dataObjectSelectedClone['admin_note']"/>
        </b-form-group>
        <custom-select v-model="dataObjectSelectedClone['payment_status']"
                       reduce="key"
                       display-field="value"
                       :options="paymentStatusList"
                       label="Trạng thái thanh toán"
        />
      </b-card-text>
      <b-card-text v-else-if="modalName === 'modal-cancel'">
        <b-form-group>
          <span>Hủy gia hạn các Proxy bên dưới đây: </span>
          <item-list :items="dataObjectSelectedListClone">
            <template #item-label="{data}">
              {{ data['ip'] }} - <span class="text-info">{{ data['plan']['name'] }}</span>
            </template>
          </item-list>
        </b-form-group>
        <span>
          <strong>Lưu ý: </strong> Chức năng này sẽ huỷ gia hạn các Proxy của quý khách. Quý khách có thể chọn phương thức huỷ dưới đây.
        </span>
        <div class="d-flex d-inline mt-1">
          <span class="mr-3">Lựa chọn phương thức huỷ: </span>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
                v-model="cancelModeSelected"
                :options="cancelOptions"
                :aria-describedby="ariaDescribedby"
                text-field="label"
                value-field="key"
            />
          </b-form-group>
        </div>
        <label v-if="cancelModeSelected === 'immediately'" class="text-danger mt-1">
          <strong>Lưu ý</strong>: Khi quý khách lựa chọn phương thức này, Proxy sẽ ngay lập tức bị huỷ và không thể khôi phục. Vui lòng đảm bảo quý khách đang xử lý đúng.
        </label>
      </b-card-text>
      <b-card-text v-else-if="modalName === 'modal-revoke-cancel'">
        <b-form-group>
          <span>Hoàn tác hủy gia hạn các Proxy bên dưới đây: </span>
          <item-list :items="dataObjectSelectedListClone">
            <template #item-label="{data}">
              {{ data['ip'] }} - <span class="text-info">{{ data['plan']['name'] }}</span>
            </template>
          </item-list>
        </b-form-group>
      </b-card-text>
      <b-card-text v-else-if="modalName === 'modal-delete'">
        <b-form-group>
          <span>Xoá các Proxy bên dưới đây: </span>
          <item-list :items="dataObjectSelectedListClone">
            <template #item-label="{data}">
              {{ data['ip'] }} - <span class="text-info">{{ data['plan']['name'] }}</span>
            </template>
          </item-list>
        </b-form-group>
      </b-card-text>
      <b-card-text v-else-if="modalName === 'modal-renew'">
        <b-form-group>
          <span>Gia hạn các Proxy bên dưới đây: </span>
          <item-list :items="dataObjectSelectedListClone">
            <template #item-label="{data}">
              {{ data['ip'] }} - <span class="text-info">{{ data['plan']['name'] }}</span>
            </template>
          </item-list>
        </b-form-group>
      </b-card-text>
    </custom-modal>
  </div>
</template>

<script>

import InputHelper from "@/mixins/utils/inputHelper";
import DataRequester from "@/mixins/utils/dataRequester";
import DataObjectList from "@/mixins/utils/dataObjectList";
import CustomModal from "@/views/components/CustomModal.vue";
import NotificationHelper from "@/mixins/notificationHelper";

import BTableCustom from "@/views/components/table/BTableCustom.vue";
import UserList from "@/views/components/UserList.vue";
import CustomInput from "@/views/components/form/CustomInput.vue";
import CustomSelect from "@/views/components/form/CustomSelect.vue";
import DateTimePicker from "@/views/components/form/DateTimePicker.vue";
import ProgressBar from "@/views/components/static/ProgressBar.vue";
import ItemList from "@/views/components/ItemList.vue";
import proxyService from "@/mixins/utils/axios/proxy.service";

export default {
  name: 'ProxyService',
  mixins: [InputHelper, DataRequester, DataObjectList, NotificationHelper],
  components: {
    ItemList,
    UserList,
    BTableCustom,
    CustomModal,
    CustomInput,
    CustomSelect,
    DateTimePicker,
    ProgressBar
  },
  computed: {
    currentModal() {
      switch(this.modalName) {
        case 'modal-edit':
          return {
            title: 'Cập nhật dịch vụ Proxy',
            variant: 'info',
            isManualClose: true,
            okTitle: 'Cập nhật',
            cancelTitle: 'Hủy',
            okAction: this.update
          }
        case 'modal-cancel':
          return {
            title: 'Hủy gia hạn dịch vụ Proxy',
            variant: 'danger',
            isManualClose: true,
            okTitle: 'Hủy gia hạn',
            cancelTitle: 'Hủy',
            okAction: this.cancel
          }
        case 'modal-revoke-cancel':
          return {
            title: 'Hoàn tác hủy gia hạn dịch vụ Proxy',
            variant: 'success',
            isManualClose: true,
            okTitle: 'Hoàn tác hủy gia hạn',
            cancelTitle: 'Hủy',
            okAction: this.revokeCancellation
          }
        case 'modal-delete':
          return {
            title: 'Xoá dịch vụ Proxy',
            variant: 'danger',
            isManualClose: true,
            okTitle: 'Xoá',
            cancelTitle: 'Hủy',
            okAction: this.deleteClientProxyService
          }
        case 'modal-renew':
          return {
            title: 'Gia hạn dịch vụ Proxy',
            variant: 'success',
            okTitle: 'Gia hạn',
            cancelTitle: 'Hủy',
            okAction: this.renewProxyService
          }
        default:
          return {
            title: '',
            variant: 'primary',
            okButton: 'OK',
            cancelButton: 'Cancel',
            okAction: function () {
              console.log('default')
            }
          }
      }
    }
  },
  data () {
    return {
      isTableLoading: false,
      columns: [
        {key: 'ip', label: 'IP'},
        {key: 'total_proxy', label: 'Số lượng proxy'},
        {key: 'plan', label: 'Gói dịch vụ'},
        {key: 'region', label: 'Khu vực'},
        {key: 'bandwidth', label: 'Băng thông'},
        {key: 'start_date', label: 'Bắt đầu'},
        {key: 'end_date', label: 'Kết thúc'},
        {key: 'owner', label: 'Chủ sở hữu'},
        {key: 'note', label: 'Ghi chú'},
        {key: 'admin_note', label: 'Ghi chú của admin'},
        {key: 'payment_status', label: 'Thanh toán'},
        {key: 'view_detail', label: 'Chi tiết'},
        {key: 'operation_status', label: 'Trạng thái'},
        {key: 'menu', label: 'Menu'}
      ],

      modalName: '',
      actionMenuList: [
        {
          name: 'modal-renew',
          label: 'Gia hạn',
          icon: 'fas fa-calendar-plus',
          color: 'success'
        },
        {type: 'divider'},
        {
          name: 'button-start-multiple',
          label: 'Bật Proxy',
          icon: 'fas fa-play',
          color: 'success'
        },
        {
          name: 'button-stop-multiple',
          label: 'Tắt Proxy',
          icon: 'fas fa-stop',
          color: 'danger'
        },
        {
          name: 'button-restart-multiple',
          label: 'Khởi động lại',
          icon: 'fas fa-redo',
          color: 'warning'
        },
        {type: 'divider'},
        {
          name: 'modal-cancel',
          label: 'Hủy gia hạn',
          icon: 'fas fa-ban',
          color: 'secondary',
          visible: (item) => {
            return item['payment_status'] !== 'cancel'
          }
        },
        {
          name: 'modal-revoke-cancel',
          label: 'Hoàn tác hủy',
          icon: 'fas fa-undo',
          color: 'success',
          visible: (item) => {
            return item['payment_status'] === 'cancel'
          }
        },
        {
          type: 'divider'
        },
        {
          name: 'modal-delete',
          label: 'Xoá',
          icon: 'fa-solid fa-trash',
          color: 'danger'
        }
      ],
      menuButtonList: [
        {
          name: 'modal-edit',
          label: 'Cập nhật',
          icon: 'fas fa-edit',
          color: 'info'
        },
        {type: 'divider'},
        {
          name: 'button-start',
          label: 'Bật Proxy',
          icon: 'fas fa-play',
          color: 'success'
        },
        {
          name: 'button-stop',
          label: 'Tắt Proxy',
          icon: 'fas fa-stop',
          color: 'danger'
        },
        {
          name: 'button-restart',
          label: 'Khởi động lại',
          icon: 'fas fa-redo',
          color: 'warning'
        },
        {type: 'divider'},
        {
          name: 'modal-cancel',
          label: 'Hủy gia hạn',
          icon: 'fas fa-ban',
          color: 'secondary',
          visible: (item) => {
            return item['payment_status'] !== 'cancel'
          }
        },
        {
          name: 'modal-revoke-cancel',
          label: 'Hoàn tác hủy',
          icon: 'fas fa-undo',
          color: 'success',
          visible: (item) => {
            return item['payment_status'] === 'cancel'
          }
        },
        {
          name: 'modal-renew',
          label: 'Gia hạn',
          icon: 'fas fa-calendar-plus',
          color: 'success',
        },
        {
          name: 'modal-auto-renew',
          label: 'Tự động gia hạn',
          icon: 'fas fa-calendar-check',
          color: 'info',
          disabled: true
        },
        {type: 'divider'},
        {
          name: 'modal-suspend',
          label: 'Tạm ngưng',
          icon: 'fas fa-pause',
          color: 'danger',
          disabled: true,
          visible: (item) => {
            return item['payment_status'] !== 'suspend'
          }
        },
        {
          name: 'modal-unsuspend',
          label: 'Kích hoạt',
          icon: 'fas fa-play',
          color: 'success',
          disabled: true,
          visible: (item) => {
            return item['payment_status'] === 'suspend'
          }
        },
        {
          name: 'modal-delete',
          label: 'Xoá',
          icon: 'fa-solid fa-trash',
          color: 'danger'
        }
      ],
      paymentStatusList: [
        {key: 'ok', value: 'Ok'},
        {key: 'cancel', value: 'Cancel'},
        {key: 'suspend', value: 'Suspend'}
      ],
      proxyPlanList: [],
      cancelModeSelected: 'end-of-billing',
      cancelOptions: [
        {
          key: 'end-of-billing',
          label: 'Huỷ khi hết hạn'
        },
        {
          key: 'immediately',
          label: 'Huỷ ngay lập tức'
        }
      ],
    }
  },
  created () {
    this.listProxyService()
    this.getProxyPlanList()
  },
  methods: {
    listProxyService() {
      let self = this
      this.isTableLoading = true
      this.runApi(proxyService.getProxyServiceList(), function (result) {
        self.dataObjectList = result.data.sort((a, b) => a['end_date'].localeCompare(b['end_date']))
      }, undefined, function () {
        self.isTableLoading = false
      })
    },
    getProxyPlanList() {
      let self = this
      this.runApi(proxyService.getProxyActivePlanList(), function (result) {
        self.proxyPlanList = result.data
      })
    },

    selectedItemOnChange(selectedItems) {
      this.dataObjectSelectedList = selectedItems
    },
    openModal (modalName, data) {
      this.modalName = modalName

      if (modalName === 'button-start' && data !== undefined) {
        this.startProxyService(data)
        return
      }
      if (modalName === 'button-stop' && data !== undefined) {
        this.stopProxyService(data)
        return
      }
      if (modalName === 'button-restart' && data !== undefined) {
        this.restartProxyService(data)
        return
      }
      if (modalName === 'button-start-multiple') {
        this.dataObjectSelectedList.forEach(x => {
          this.startProxyService(x)
        })
        return
      }
      if (modalName === 'button-stop-multiple') {
        this.dataObjectSelectedList.forEach(x => {
          this.stopProxyService(x)
        })
        return
      }
      if (modalName === 'button-restart-multiple') {
        this.dataObjectSelectedList.forEach(x => {
          this.restartProxyService(x)
        })
        return
      }

      if (data !== undefined) {
        this.dataObjectSelected = data
        this.dataObjectSelectedClone = {...data}
        this.dataObjectSelectedList = [data].flat()
        this.dataObjectSelectedListClone= [...this.dataObjectSelectedList]
      } else {
        this.dataObjectSelectedList = this.$refs['tableData'].getSelectedData()
        this.dataObjectSelectedListClone= [...this.dataObjectSelectedList]
      }

      if (this.dataObjectSelectedList.length === 0) {
        this.popupError('Vui lòng chọn ít nhất 1 dòng dữ liệu')
        return
      }

      this.$nextTick(() => {
        this.$refs['proxy-service-modal'].show()
      })
    },
    closeModal() {
      this.$refs['proxy-service-modal'].hide()
    },

    update() {
      let self = this
      this.runApi(proxyService.updateProxyService(this.dataObjectSelectedClone), function () {
        self.listProxyService()
      }, undefined, function () {
        self.closeModal()
      })
    },
    checkOperationStatus (rowData) {
      rowData['operation_status'] = 'Checking'
      this.runApi(proxyService.checkStatusProxyService(rowData['id']), function (result) {
        rowData['operation_status'] = result.data['operation_status']
      }, function () {
        rowData['operation_status'] = 'Unknown'
      })
    },
    cancel () {
      const self = this
      self.runApi(proxyService.cancelProxyService(self.dataObjectSelectedListClone.map(x => x['id']), self.cancelModeSelected),
          function () {
            self.dataObjectSelectedListClone.forEach(x => {
              x['payment_status'] = 'cancel'
            })
            if (self.cancelModeSelected === 'immediately') {
              self.removeRowDataByIds(self.dataObjectSelectedListClone.map(x => x['id']))
            }
          }, undefined, function () {
            self.closeModal()
          }, true)
    },
    revokeCancellation () {
      const self = this
      self.runApi(proxyService.revokeCancellationProxyService(self.dataObjectSelectedListClone.map(x => x['id'])),
          function () {
            self.dataObjectSelectedListClone.forEach(x => {
              x['payment_status'] = 'ok'
            })
          }, undefined, function () {
            self.closeModal()
          }, true)
    },
    startProxyService (rowData) {
      const self = this
      self.setItemProperty(rowData, 'operation_status', 'Starting')
      this.runApi(proxyService.startProxyService(rowData['id']), function (result) {
        self.setItemProperty(rowData, 'operation_status', result.data['operation_status'])
      }, function () {
        self.setItemProperty(rowData, 'operation_status', 'Unknown')
      })
    },
    stopProxyService (rowData) {
      const self = this
      self.setItemProperty(rowData, 'operation_status', 'Stopping')
      this.runApi(proxyService.stopProxyService(rowData['id']), function (result) {
        self.setItemProperty(rowData, 'operation_status', result.data['operation_status'])
      }, function () {
        self.setItemProperty(rowData, 'operation_status', 'Unknown')
      })
    },
    restartProxyService (rowData) {
      const self = this
      self.setItemProperty(rowData, 'operation_status', 'Restarting')
      this.runApi(proxyService.restartProxyService(rowData['id']), function (result) {
        self.setItemProperty(rowData, 'operation_status', result.data['operation_status'])
      }, function () {
        self.setItemProperty(rowData, 'operation_status', 'Unknown')
      })
    },
    deleteClientProxyService () {
      const self = this
      self.dataObjectSelectedListClone.forEach(rowData => {
        self.setItemProperty(rowData, 'operation_status', 'Deleting')
        this.runApi(proxyService.deleteClientProxyService(rowData['id']), function (result) {
          self.removeRowDataByIds([rowData['id']])
        }, function () {
          self.setItemProperty(rowData, 'operation_status', 'Unknown')
        }, undefined, true)
      })
      self.closeModal()
    },
    renewProxyService () {
      if (this.dataObjectSelectedList.length === 0) {
        this.popupError('Vui lòng chọn ít nhất 1 dịch vụ để gia hạn')
        return
      }

      let uniqueOwners = [...new Set(this.dataObjectSelectedList.map(x => x['owner']['uuid']))]
      let hasCancelledService = this.dataObjectSelectedList.some(x => x['payment_status'] === 'cancel')

      if (uniqueOwners.length > 1) {
        this.popupError('Không thể chọn nhiều người dùng khác nhau để gia hạn')
        return
      } else if (hasCancelledService) {
        this.popupError('Không thể chọn dịch vụ đã hủy để gia hạn')
        return
      }


      let url = this.$router.resolve({
        name: 'invoice-preview',
        params: {
          type: 'renew'
        },
        query: {
          id: this.dataObjectSelectedList.map(x => x['id']),
          category: 'proxy'
        }
      })
      window.open(url.href, '_blank')
    }
  }
}
</script>
